<template>
	<!-- type: 댓글 많은 유형 -->
	<article v-if="type === 'full'">
		<!-- 게시글 Article -->
		<div class="scomm-detail-article">
			<div class="scomm-detail-article__header">
				<!-- 사용자 정보 -->
				<div class="scomm-detail-user">
					<div class="scomm-detail-user__photo">
						<img src="" alt="" />
					</div>
					<div class="scomm-detail-user__text">
						<span class="scomm-detail-user__name">김가치</span>
						<span class="scomm-detail-user__date">2023.02.01 14:32</span>
					</div>
				</div>
				<!-- //사용자 정보 -->
				<button type="button" class="button-open-util" @click="toggleShow('util1')">
					<i class="icon-detail-more"></i>
				</button>
				<div v-show="showUtil1" class="scomm-detail-article__util">
					<button type="button" class="button" @click="showEditLayer('util1')">수정</button>
					<button type="button" class="button" @click="deleteConfirm()">삭제</button>
				</div>
				<!-- 게시글 수정 레이어 -->
				<div v-if="showEdit1" class="layer-scomm-edit">
					<div class="layer-scomm-edit__header">
						<button type="button" @click="closeLayer()"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
					</div>
					<div class="scomm-detail-write scomm-detail-write__main">
						<form action="#">
							<fieldset>
								<legend class="sr-only">게시글 수정</legend>
								<textarea name="" id="" cols="30" rows="10" class="textarea">
									커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. 
				아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
								</textarea
								>
								<div class="scomm-detail-write__footer">
									<div class="flex-l">
										<span class="scomm-detail-write__upload" v-if="previewImage">
											<em class="thumb">
												<img :src="previewImage" />
											</em>
											<button type="button" class="button-delete" @click="cancelUpload">
												<span class="sr-only">이미지 삭제</span>
											</button>
										</span>
										<span class="button-upload-photo" v-else>
											<label>
												<input ref="fileInput" type="file" accept="image/*" @change="onFileSelected()" />
												<i class="icon-detail-photo"></i>
												<span class="sr-only">이미지등록</span>
											</label>
										</span>
										<span class="scomm-detail-write__footer-count">0/1000</span>
									</div>
									<div class="flex-r">
										<button type="submit" class="button-default">수정</button>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
				<!-- //게시글 수정 레이어 -->
			</div>
			<div class="scomm-detail-article__content">
				<span class="keyword">소상공인</span> 커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. <br />
				아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
			</div>
			<div class="scomm-detail-article__image">
				<LightBox :thumbnail="require('@/assets/img/@tmp/tmp_thumb.jpg')" :largeImage="require('@/assets/img/@tmp/tmp_image.jpg')" captions="" class="lightBox" />
			</div>
			<div class="scomm-detail-article__footer">
				<button type="button" class="button" @click="toggleLike()">
					<i :class="{ 'icon-detail-like-o': !checkLike, 'icon-detail-like': checkLike }"></i>
					<span class="text">좋아요</span>
					<span class="nums">12</span>
				</button>
				<button type="button" class="button" @click="toggleShow('sub')">
					<i class="icon-detail-comment"></i>
					<span class="text">댓글</span>
					<span class="nums">1</span>
				</button>
			</div>
		</div>
		<!-- //게시글 Article -->
		<!--  댓글 -->
		<ul v-show="showSub">
			<li>
				<!-- 댓글 Article -->
				<div class="scomm-detail-article">
					<div class="scomm-detail-article__header">
						<!-- 사용자 정보 -->
						<div class="scomm-detail-user">
							<div class="scomm-detail-user__photo">
								<img src="" alt="" />
							</div>
							<div class="scomm-detail-user__text">
								<span class="scomm-detail-user__name">
									김가치
									<em class="scomm-detail-user__tag">작성자</em>
								</span>
							</div>
						</div>
						<!-- //사용자 정보 -->
						<button type="button" class="button-open-util" @click="toggleShow('util2')">
							<i class="icon-detail-more"></i>
						</button>
						<div v-show="showUtil2" class="scomm-detail-article__util">
							<button type="button" class="button" @click="showEditLayer('util2')">수정</button>
							<button type="button" class="button" @click="deleteConfirm()">삭제</button>
						</div>
						<!-- 글 수정 레이어 -->
						<div v-if="showEdit2" class="layer-scomm-edit">
							<div class="layer-scomm-edit__header">
								<button type="button" @click="closeLayer()"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
							</div>
							<div class="scomm-detail-write">
								<form action="#">
									<fieldset>
										<legend class="sr-only">수정</legend>
										<textarea name="" id="" cols="30" rows="10" class="textarea">
									커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. 
				아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
								</textarea
										>
										<div class="scomm-detail-write__footer">
											<div class="flex-l">
												<span class="scomm-detail-write__footer-count">0/150</span>
											</div>
											<div class="flex-r">
												<button type="submit" class="button-default">수정</button>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
						<!-- //글 수정 레이어 -->
					</div>
					<div class="scomm-detail-article__content">
						<span class="keyword">소상공인</span> 커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. <br />
						아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
					</div>
					<div class="scomm-detail-article__footer">
						<span class="date"> 2023.02.01 14:32 </span>
						<button type="button" class="button" @click="toggleShow('rewrite')">
							<span class="text is-strong">답글쓰기</span>
						</button>
					</div>
				</div>
				<!-- //댓글 Article -->
				<!--  답글 -->
				<ul>
					<li v-show="showReWrite">
						<!-- 답글 등록 -->
						<div class="scomm-detail-write">
							<form action="#">
								<fieldset>
									<legend class="sr-only">답글 등록</legend>
									<div class="scomm-detail-write__header"><strong>김가치</strong></div>
									<textarea name="" id="" cols="30" rows="10" class="textarea"></textarea>
									<div class="scomm-detail-write__footer">
										<div class="flex-l">
											<span class="scomm-detail-write__footer-count">0/150</span>
										</div>
										<div class="flex-r">
											<button type="submit" class="button-default">등록</button>
										</div>
									</div>
								</fieldset>
							</form>
						</div>
						<!-- //답글 등록 -->
					</li>
					<li>
						<!-- 답글 Article -->
						<div class="scomm-detail-article">
							<div class="scomm-detail-article__header">
								<!-- 사용자 정보 -->
								<div class="scomm-detail-user">
									<div class="scomm-detail-user__photo">
										<img src="" alt="" />
									</div>
									<div class="scomm-detail-user__text">
										<span class="scomm-detail-user__name">
											김가치
											<em class="scomm-detail-user__tag">작성자</em>
										</span>
									</div>
								</div>
								<!-- //사용자 정보 -->
								<button type="button" class="button-open-util" @click="toggleShow('util3')">
									<i class="icon-detail-more"></i>
								</button>
								<div v-show="showUtil3" class="scomm-detail-article__util">
									<button type="button" class="button" @click="showEditLayer('util3')">수정</button>
									<button type="button" class="button" @click="deleteConfirm()">삭제</button>
								</div>
								<!-- 글 수정 레이어 -->
								<div v-if="showEdit3" class="layer-scomm-edit">
									<div class="layer-scomm-edit__header">
										<button type="button" @click="closeLayer()"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
									</div>
									<div class="scomm-detail-write">
										<form action="#">
											<fieldset>
												<legend class="sr-only">수정</legend>
												<textarea name="" id="" cols="30" rows="10" class="textarea">
									커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. 
				아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
								</textarea
												>
												<div class="scomm-detail-write__footer">
													<div class="flex-l">
														<span class="scomm-detail-write__footer-count">0/150</span>
													</div>
													<div class="flex-r">
														<button type="submit" class="button-default">수정</button>
													</div>
												</div>
											</fieldset>
										</form>
									</div>
								</div>
								<!-- //글 수정 레이어 -->
							</div>
							<div class="scomm-detail-article__content">
								<span class="keyword">소상공인</span> 커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. <br />
								아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
							</div>
							<div class="scomm-detail-article__footer">
								<span class="date"> 2023.02.01 14:32 </span>
							</div>
						</div>
						<!-- //답글 Article -->
					</li>
				</ul>
				<!--  //답글 -->
			</li>
			<li>
				<!-- 댓글 등록 -->
				<div class="scomm-detail-write">
					<form action="#">
						<fieldset>
							<legend class="sr-only">댓글 등록</legend>
							<div class="scomm-detail-write__header"><strong>김가치</strong></div>
							<textarea name="" id="" cols="30" rows="10" class="textarea"></textarea>
							<div class="scomm-detail-write__footer">
								<div class="flex-l">
									<span class="scomm-detail-write__footer-count">0/150</span>
								</div>
								<div class="flex-r">
									<button type="submit" class="button-default">등록</button>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
				<!-- //댓글 등록 -->
			</li>
		</ul>
		<!--  //댓글 -->
	</article>

	<!-- type: 댓글 없는 유형 -->
	<article v-else>
		<!-- 게시글 Article -->
		<div class="scomm-detail-article">
			<div class="scomm-detail-article__header">
				<!-- 사용자 정보 -->
				<div class="scomm-detail-user">
					<div class="scomm-detail-user__photo">
						<img src="" alt="" />
					</div>
					<div class="scomm-detail-user__text">
						<span class="scomm-detail-user__name">김가치</span>
						<span class="scomm-detail-user__date">2023.02.01 14:32</span>
					</div>
				</div>
				<!-- //사용자 정보 -->
				<button type="button" class="button-open-util" @click="toggleShow('util1')">
					<i class="icon-detail-more"></i>
				</button>
				<div v-show="showUtil1" class="scomm-detail-article__util">
					<button type="button" class="button" @click="showEditLayer('util1')">수정</button>
					<button type="button" class="button" @click="deleteConfirm()">삭제</button>
				</div>
				<!-- 게시글 수정 레이어 -->
				<div v-if="showEdit1" class="layer-scomm-edit">
					<div class="layer-scomm-edit__header">
						<button type="button" @click="closeLayer()"><i class="icon-popup-close"></i><span class="sr-only">팝업 창닫기</span></button>
					</div>
					<div class="scomm-detail-write scomm-detail-write__main">
						<form action="#">
							<fieldset>
								<legend class="sr-only">게시글 수정</legend>
								<textarea name="" id="" cols="30" rows="10" class="textarea">
									커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. 
									아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
								</textarea
								>
								<div class="scomm-detail-write__footer">
									<div class="flex-l">
										<span class="scomm-detail-write__upload" v-if="previewImage">
											<em class="thumb">
												<img :src="previewImage" />
											</em>
											<button type="button" class="button-delete" @click="cancelUpload">
												<span class="sr-only">이미지 삭제</span>
											</button>
										</span>
										<span class="button-upload-photo" v-else>
											<label>
												<input ref="fileInput" type="file" accept="image/*" @change="onFileSelected()" />
												<i class="icon-detail-photo"></i>
												<span class="sr-only">이미지등록</span>
											</label>
										</span>
										<span class="scomm-detail-write__footer-count">0/1000</span>
									</div>
									<div class="flex-r">
										<button type="submit" class="button-default">수정</button>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
				<!-- //게시글 수정 레이어 -->
			</div>
			<div class="scomm-detail-article__content">
				<span class="keyword">소상공인</span> 커뮤니티에 대해서 의문이 있는 부분은 별도로 댓글 문의 하시기 바랍니다. <br />
				아울러 앞으로 많은 참여 부탁 드리겠습니다. 감사합니다.
			</div>
			<div class="scomm-detail-article__image">
				<LightBox :thumbnail="require('@/assets/img/@tmp/tmp_thumb.jpg')" :largeImage="require('@/assets/img/@tmp/tmp_image.jpg')" captions="" class="lightBox" />
			</div>
			<div class="scomm-detail-article__footer">
				<button type="button" class="button">
					<i :class="{ 'icon-detail-like-o': !checkLike, 'icon-detail-like': checkLike }"></i>
					<span class="text">좋아요</span>
					<span class="nums">0</span>
				</button>
				<button type="button" class="button" @click="toggleShow('sub')">
					<span class="text">댓글쓰기</span>
				</button>
			</div>
		</div>
		<!-- //게시글 Article -->
		<!--  댓글 -->
		<ul v-show="showSub">
			<li>
				<!-- 댓글 등록 -->
				<div class="scomm-detail-write">
					<form action="#">
						<fieldset>
							<legend class="sr-only">댓글 등록</legend>
							<div class="scomm-detail-write__header"><strong>김가치</strong></div>
							<textarea name="" id="" cols="30" rows="10" class="textarea"></textarea>
							<div class="scomm-detail-write__footer">
								<div class="flex-l">
									<span class="scomm-detail-write__footer-count">0/150</span>
								</div>
								<div class="flex-r">
									<button type="submit" class="button-default">등록</button>
								</div>
							</div>
						</fieldset>
					</form>
				</div>
				<!-- //댓글 등록 -->
			</li>
		</ul>
		<!--  //댓글 -->
	</article>
</template>

<script>
import LightBox from '@/components/common/LightBox';
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM } from '../../store/_mut_consts';
export default {
	name: 'ScommunityArticle',
	components: {
		LightBox,
	},
	data: () => ({
		previewImage: null,
		showSub: false,
		showReWrite: false,
		showUtil1: false,
		showUtil2: false,
		showUtil3: false,
		showEdit1: false,
		showEdit2: false,
		showEdit3: false,
		checkLike: false,
	}),
	props: {
		type: null,
	},
	methods: {
		onFileSelected() {
			let input = this.$refs.fileInput;
			let file = input.files;
			if (file && file[0]) {
				let reader = new FileReader();
				reader.onload = (e) => {
					this.previewImage = e.target.result;
				};
				reader.readAsDataURL(file[0]);
				this.$emit('input', file[0]);
			}
		},
		cancelUpload() {
			this.previewImage = null;
		},
		toggleShow(target) {
			if (target == 'sub') {
				this.showSub = !this.showSub;
			}
			if (target == 'rewrite') {
				this.showReWrite = !this.showReWrite;
			}
			if (target == 'util1') {
				this.showUtil1 = !this.showUtil1;
				this.showUtil2 = false;
				this.showUtil3 = false;
			}
			if (target == 'util2') {
				this.showUtil2 = !this.showUtil2;
				this.showUtil1 = false;
				this.showUtil3 = false;
			}
			if (target == 'util3') {
				this.showUtil3 = !this.showUtil3;
				this.showUtil1 = false;
				this.showUtil2 = false;
			}
		},
		showEditLayer(target) {
			this.showUtil1 = false;
			this.showUtil2 = false;
			this.showUtil3 = false;
			this.showEdit1 = false;
			this.showEdit2 = false;
			this.showEdit3 = false;

			if (target == 'util1') {
				this.showEdit1 = !this.showEdit1;
			}
			if (target == 'util2') {
				this.showEdit2 = !this.showEdit2;
			}
			if (target == 'util3') {
				this.showEdit3 = !this.showEdit3;
			}
		},
		deleteConfirm() {
			// this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '텍스트 내용', html: true });
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `게시물을 삭제하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					// this.$router.push({ name: 'Main' });
				},
				nofunc: () => {
					this.isLoading = false;
				},
			});
			this.showUtil1 = false;
			this.showUtil2 = false;
			this.showUtil3 = false;
		},
		closeLayer() {
			this.showEdit1 = false;
			this.showEdit2 = false;
			this.showEdit3 = false;
		},
		toggleLike() {
			this.checkLike = !this.checkLike;
		},
	},
};
</script>
